import {makeStyles} from '@theme/makeStyles';
import {Theme} from '@mui/material';
import {bool, number, string} from 'prop-types';
import React from 'react';
import PrevIcon from '@mui/icons-material/NavigateBeforeRounded';
import clsx from 'clsx';
import NextIcon from '@mui/icons-material/NavigateNextRounded';

const useStyles = makeStyles()((theme: Theme) => ({
    icon: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        borderRadius: '100%',
        // These don't need to depend on theme since they're going to be on top of a random image
        background: 'rgba(0,0,0, 0.55)',
        color: 'rgba(255,255,255,0.85)',
        opacity: 0,
        transition: 'opacity 300ms',
        cursor: 'pointer',
        // zIndex: 3000,
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        width: 50,
        height: 50
    },

    active: {
        opacity: 1
    },

    prev: {
        left: 0
    },

    next: {
        right: 0
    }
}));

interface SliderArrowsProps {
    active: boolean;
    currentSlide: number;
    slideCount: number;
    className?: string;
    next?: () => void;
    prev?: () => void;
    setSlide?: (slide: number) => void;
}

const SliderArrows = (props: SliderArrowsProps): JSX.Element => {
    const { classes, cx } = useStyles();
    const {next, prev, setSlide, className, currentSlide, active, slideCount} = props;
    const nextHandler = React.useCallback(() => {
        if (setSlide) {
            return setSlide(currentSlide + 1);
        }

        return next();
    }, [next, setSlide, currentSlide]);

    const prevHandler = React.useCallback(() => {
        if (setSlide) {
            return setSlide(currentSlide - 1);
        }

        return prev();
    }, [prev, setSlide, currentSlide]);

    return <>
        {currentSlide > 0 && <PrevIcon aria-label='prev arrow' onClick={prevHandler} className={clsx(cx(classes.icon, classes.prev, active && classes.active, className), 'swiper-no-swiping')} />}
        {currentSlide < slideCount - 1 && <NextIcon aria-label='next arrow' onClick={nextHandler} className={clsx(cx(classes.icon, classes.next, active && classes.active, className), 'swiper-no-swiping')} />}
    </>;
};

// Prop validation

const nextAndPrevValidation = (props, propName) => {
    if (props[propName] !== undefined && typeof props[propName] !== 'function') {
        return new Error(`'${propName}' is expected to be a function in SliderArrows.tsx`);
    }

    if (props.next === undefined || props.prev === undefined) {
        return new Error('Either both \'prev\' and \'next\' should be defined, or \'setSlide\' should be defined in SliderArrows.tsx');
    }

    if (props.setSlide !== undefined) {
        return new Error(`'${propName}' and 'setSlide' should not be both defined in SliderArrows.tsx. Pick one or the other`);
    }
};

SliderArrows.propTypes = {
    active: bool.isRequired,
    currentSlide: number.isRequired,
    slideCount: number.isRequired,
    className: string,
    next:nextAndPrevValidation,
    prev: nextAndPrevValidation,
    setSlide: (props) => {
        if (props.setSlide !== undefined && typeof props.setSlide !== 'function') {
            return new Error('\'setSlide\' is expected to be a function in SliderArrows.tsx');
        }

        if (props.setSlide !== undefined && (props.next !== undefined || props.prev !== undefined)) {
            return new Error('\'next\' or \'prev\' and \'setSlide\' should not be both defined in SliderArrows.tsx. Pick one or the other');
        }
    }
};

export default SliderArrows;