import {SoundBadge, SpoilerBadge} from '@components/NewMediaSlider/EyePurchaser/Badges';
import React, {useEffect, useRef, useState} from 'react';
import useHover from '@hooks/Binders/useHover';
import {MediaSliderConfiguration} from '@components/NewMediaSlider/utils';
import useVisibleElement from '@hooks/Utils/useVisibleElement';
import {makeStyles} from '@theme/makeStyles';
import PostUnlockButton from '@components/NewMediaSlider/PostUnlockButton';
import {useNewMediaSliderContext} from '@components/NewMediaSlider/Context';
import BigPlayButton from '@components/VideoPlayer/BigPlayButton';
import Eye from '../../../public/static/img/eye.svg';
import SVGImage from '@components/Utils/SVGImage';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import ShadowButtonIcon from '@components/Utils/Buttons/ShadowButtonIcon';
import {useTranslation} from 'react-i18next';

interface SpoilerPreviewProps {
    config: MediaSliderConfiguration;
    payHandler: () => void;
    staticVideo?: boolean;
    withoutUnlockButton?: boolean;
    withoutSoundButton?: boolean;
    autoPlay?: boolean;
    onlyBackground?: boolean;
    className?: string;
}

const useStyles = makeStyles()(() => ({
    imagePreview: {
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    viewButton: {
        '& > span': {
            paddingLeft: 3
        }
    }
}));

const SpoilerPreview = (props: SpoilerPreviewProps): JSX.Element => {
    const {config, payHandler, staticVideo, withoutUnlockButton, withoutSoundButton, autoPlay = true, onlyBackground = false, className} = props;
    const {classes, cx} = useStyles();
    const {t} = useTranslation('post');

    const mediaContext = useNewMediaSliderContext();
    const [hoverable, isHovering] = mediaContext && mediaContext.hover || useHover();
    const [muted, setMuted] = useState<boolean>(true);

    const videoRef = useRef<HTMLVideoElement>();
    const wrapperRef = useRef<HTMLDivElement>();

    const isVisible = useVisibleElement(wrapperRef, 1);

    // Play for 100ms to allow iOS to show the video
    // useEffect(() => {
    //     if (videoRef && videoRef.current) {
    //         videoRef.current.play();
    //     }
    //
    //     setTimeout(() => {
    //         if (videoRef && videoRef.current) {
    //             videoRef.current.pause();
    //         }
    //     }, 100);
    // }, []);

    // Autoplay on hover
    useEffect(() => {
        if (videoRef && videoRef.current) {
            if (isHovering) {
                videoRef.current.play();
            }
            else {
                if (autoPlay && isVisible) {
                    // Do nothing
                }
                else {
                    videoRef.current.pause();
                }
            }
        }
    }, [videoRef, isHovering, isVisible, autoPlay]);

    // Autoplay visibility
    useEffect(() => {
        if (!autoPlay) {
            return;
        }

        if (videoRef && videoRef.current) {
            if (isVisible) {
                videoRef.current.play();
            }
            else if (!isHovering) {
                videoRef.current.pause();
            }
        }
    }, [videoRef, isVisible, autoPlay, isHovering]);

    if (!config.preview.file) {
        return <div/>;
    }

    const isVideo = config.preview.type === 'videoPreview' && config.type === 'paid';
    const pathToUse = config.preview.file.visible ? config.preview.urls.nonBlur : config.preview.urls.blur;

    if (!onlyBackground && !pathToUse) {
        return <div/>;
    }

    const isImageHorizontal = onlyBackground ? true : (pathToUse.width > pathToUse.height);
    const showEye = !config.preview.file.visible && !config.isVisible;
    const hasVideo = config.files.some(f => f.type === 'video');
    const showPlayButton = (!config.preview.file.visible && config.isVisible && hasVideo) || (config.type === 'free' && hasVideo);
    const showViewImagesButton = !config.preview.file.visible && config.isVisible && !hasVideo;
    const showSpoilerBadge = !config.isMyContent && config.type !== 'free';

    const handleClick = () => {
        if (mediaContext && mediaContext.isMediaPlayable) {
            if (config.isVisible) {
                mediaContext.setImageDialogOpen(0);
            }
            else {
                payHandler();
            }
        }
    };

    if (isVideo && !staticVideo) {
        return <div style={{width: '100%', height: '100%', position: 'relative'}} ref={wrapperRef}>
            <video
                {...hoverable}
                src={config.preview.urls.video.url}
                width={'100%'}
                height={'100%'}
                ref={videoRef}
                muted={muted}
                playsInline
                autoPlay={autoPlay}
                loop
                onClick={handleClick}
                aria-label={'open post'}
                style={{
                    backgroundImage: `url(${config.preview.urls.backgroundBlur.url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
            />

            {!config.isMyContent && (
                <div style={{position: 'absolute', top: 5, right: 5}} {...hoverable}>
                    <SpoilerBadge/>
                </div>
            )}

            {!withoutSoundButton && (
                <div style={{position: 'absolute', bottom: 5, right: 5}} {...hoverable}>
                    <SoundBadge muted={muted} onClick={() => setMuted(prev => !prev)} aria-label={'mute/unmute video'} />
                </div>
            )}
        </div>;
    }

    return <div style={{backgroundImage: `url(${config.preview.urls.backgroundBlur.url})`}} className={cx(classes.imagePreview, className)}>

        {(isVideo && staticVideo || showPlayButton) && (<BigPlayButton clickPreview={() => {/* no-op */}} />)}

        {!onlyBackground && (
            <img
                src={pathToUse.url}
                width={isImageHorizontal ? '100%' : undefined}
                height={isImageHorizontal ? undefined : '100%'}
                onClick={handleClick}
                aria-label={'open post'}
            />
        )}

        {showEye && (
            <div className={'absoluteCenter'} style={{width: '50%'}}>
                <SVGImage svg={Eye} color={'secondary'} fill={'logo-light'} removeStroke removeMargin width={'100%'} height={'100%'} />
            </div>
        )}

        {showViewImagesButton && (
            <ShadowButtonIcon
                className={cx(classes.viewButton, ' absoluteCenter')}
                label={t('VIEW_IMAGES')}
                icon={<PhotoSizeSelectActualIcon/>}
                onClick={handleClick}
                aria-label={'view images'}
            />
        )}

        {showSpoilerBadge && (
            <div style={{position: 'absolute', top: 5, right: 5}} {...hoverable}>
                <SpoilerBadge/>
            </div>
        )}

        {!withoutUnlockButton && config.type === 'subscribers' && !showViewImagesButton && (
            <div style={{position: 'absolute', bottom: 0, left: 0, padding: 10, width: '100%'}}>
                <PostUnlockButton config={config} payHandler={payHandler} price={null} />
            </div>
        )}
    </div>;
};

export default SpoilerPreview;