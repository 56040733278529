import React, {useRef, useState} from 'react';
import {MediaSliderConfiguration, MediaSliderFile} from '@components/NewMediaSlider/utils';
import {animated} from '@react-spring/web';
import useSlider from '@components/NewMediaSlider/useSlider';
import SliderArrows from '@components/NewMediaSlider/SliderArrows';
import useHover from '@hooks/Binders/useHover';
import {useThemeContext} from '@theme/ThemeContext';
import {makeStyles} from '@theme/makeStyles';
import {useFileRenderer} from '@components/NewMediaSlider/useFileRenderer';
import {HandlePay} from '@components/Payment/MediaPaymentModal';
import {Box, Skeleton} from '@mui/material';
import SliderDots from '@components/NewMediaSlider/SliderDots';
import DisplayInfo from '@components/NewMediaSlider/DisplayInfo';
import {useAppContext} from '@context/AppContext';
import useSwiperClick from '@hooks/Binders/useSwiperClick';
import {POST_BORDER_RADIUS} from '../../Constants';
import {useNewMediaSliderContext} from '@components/NewMediaSlider/Context';
import PostUnlockButton from '@components/NewMediaSlider/PostUnlockButton';
import {LockBadge} from '@components/NewMediaSlider/EyePurchaser/Badges';
import useFilePaymentModal from '@hooks/Utils/useFilePaymentModal';
import ShadowButtonIcon from '@components/Utils/Buttons/ShadowButtonIcon';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import {useTranslation} from 'react-i18next';
import {SITE_ROUTES} from '../../Constants/routes';

const useStyles = makeStyles()(() => ({
    slider: {
        display: 'flex',
        flexWrap: 'nowrap',
        '& > div': {
            flex: '0 0 auto'
        },
        userSelect: 'none',
        touchAction: 'pan-y'
    },
    sliderWrapper: {
        overflow: 'hidden'
    },
    skeleton: {
        transform: 'none',
        position: 'absolute'
    },
    roundBorder: {
        borderRadius: POST_BORDER_RADIUS,
        '-webkit-border-radius': POST_BORDER_RADIUS,
        '-moz-border-radius': POST_BORDER_RADIUS
    },
    noBorder: {
        borderRadius: 0
    },
    viewButton: {
        '& > span': {
            paddingLeft: 3
        }
    },
    blurCoverContainer: {
        width: '100%',
        height: '100%',
        cursor: 'pointer'
    },
    blurCover: {
        filter: 'blur(30px)'
    }
}));

interface NewMediaSliderProps {
    config: MediaSliderConfiguration;
    payHandler: HandlePay;
    price?: number;
    initialSlide?: number;
    disableRoundedBorders?: boolean;
    disableBadges?: boolean;
    fillImage?: boolean;
    blurCover?: boolean;
    limitMaxHeight?: boolean;
}

const RenderedFile = (props: {index: number, config: MediaSliderConfiguration, file: MediaSliderFile, payHandler: HandlePay, price?: number, disableRoundedBorders?: boolean, fillImage?: boolean}): JSX.Element => {
    const {index, config, file, payHandler, price, disableRoundedBorders, fillImage} = props;
    const {mediaClickHandler} = useNewMediaSliderContext();

    const File = useFileRenderer(config, file, payHandler, price, {disableRoundedBorders, fillImage});

    const swipable = useSwiperClick(() => {
        mediaClickHandler(config, index, false);
    }, 'open chat media modal');

    return <div {...swipable} style={{cursor: 'pointer', width: '100%', height: '100%'}}>{File}</div>;
};

const NewMediaSlider = (props: NewMediaSliderProps): JSX.Element => {
    const {config, payHandler, price, initialSlide, disableRoundedBorders, disableBadges = false, blurCover: blurCoverProps = false, limitMaxHeight = false} = props;
    const {classes, cx} = useStyles();
    const handlePay = useFilePaymentModal(config, payHandler, price);

    const sliderRef = useRef<HTMLDivElement>();
    const {isDesktop} = useThemeContext();
    const {authUser, router} = useAppContext();
    const {t} = useTranslation(['post', 'chat']);
    
    const [blurCover, setBlurCover] = useState(blurCoverProps);

    const {isMediaPlayable, hover: fileHover, setImageDialogOpen} = useNewMediaSliderContext();

    const {springProps, currentSlide, next, prev} = useSlider({
        initialSlide: initialSlide || 0,
        ref: sliderRef,
        shouldCancel: false,
        containerWidth: config.width,
        numSlides: config.nbFiles
    });

    const [hoverable, hover] = useHover();
    const [fileHoverable] = fileHover;
    const isMyContent = authUser && authUser.data.id === config.user.id;

    const sliderArrows = (id) => <SliderArrows
        active={hover && config.files[currentSlide] && config.files[currentSlide].id === id}
        next={next}
        prev={prev}
        currentSlide={currentSlide}
        slideCount={config.nbFiles}
    />;

    // Find first file
    const firstFile = config.files.find(() => true);
    const isVideo = config.files.length === 1 && firstFile && firstFile.type === 'video';

    // TODO: Re-add commented condition when inline slider is enabled
    const showSlider = config.type === 'free'; // || (isMediaPlayable && config.isVisible);
    const showSliderDots = showSlider && config.nbFiles > 1;

    const inChat = router.pathname === SITE_ROUTES.CHAT_PATH || router.pathname === SITE_ROUTES.CHATS;
    // const showViewButton = isMediaPlayable && config.isVisible && !isVideo && config.type !== 'free' && !inChat;
    const showViewButton = false;

    if (!firstFile) {
        return <div/>;
    }

    const maxHeight = limitMaxHeight ? config.width*9/16 : undefined;

    return (
        <>
            <div style={{width: config.width, height: config.height, position: 'relative', maxHeight}} className={cx(classes.sliderWrapper, !disableRoundedBorders ? classes.roundBorder : undefined)} {...fileHoverable}>
                <Skeleton width={config.width} height={config.height} className={cx(classes.skeleton, !disableRoundedBorders ? classes.roundBorder : classes.noBorder)} style={{maxHeight}} />

                {showSlider ? (
                    <animated.div ref={sliderRef} style={springProps} {...hoverable} className={cx(classes.slider, blurCover ? classes.blurCover : undefined)}>
                        {config.files.map((file, i) => {
                            return (
                                <div key={file.id} style={{position: 'relative', width: config.width, height: config.height}}>
                                    <RenderedFile
                                        index={i}
                                        config={config}
                                        file={file}
                                        payHandler={payHandler}
                                        price={price}
                                        disableRoundedBorders={disableRoundedBorders}
                                        fillImage={props.fillImage}
                                    />

                                    {isDesktop && sliderArrows(file.id)}
                                </div>
                            );
                        })}
                    </animated.div>
                ) : (
                    <div key={firstFile.id} style={{position: 'relative', width: config.width, height: config.height, maxHeight}}>
                        <RenderedFile
                            index={0}
                            config={config}
                            file={firstFile}
                            payHandler={payHandler}
                            price={price}
                            disableRoundedBorders={disableRoundedBorders}
                            fillImage={props.fillImage}
                        />
                    </div>
                )}

                {blurCover && (
                    <div className={classes.blurCoverContainer + ' absoluteCenter'} onClick={() => setBlurCover(false)} aria-label={'view files'}>
                        <ShadowButtonIcon
                            label={isVideo ? t('chat:VIEW_VIDEO') : t('chat:VIEW_IMAGES', {count: config.nbFiles})}
                            onClick={() => setBlurCover(false)}
                            aria-label={'view files'}
                        />
                    </div>
                )}

                {!disableBadges && isMyContent && (
                    <div style={{position: 'absolute', top: 5, right: 5}}>
                        <DisplayInfo
                            price={price}
                            isMyContent={isMyContent}
                            private={config.isPrivate}
                            status={config.contentStatus}
                        />
                    </div>
                )}

                {!disableBadges && config.type !== 'free' && (
                    <div style={{position: 'absolute', top: 5, left: 5}}>
                        <LockBadge locked={!config.isVisible} files={config.files} />
                    </div>
                )}
            </div>

            {price && (!isMediaPlayable || inChat) && (
                <Box mt={1}>
                    <PostUnlockButton config={config} payHandler={handlePay} price={price}/>
                </Box>
            )}

            {showSliderDots && (
                <Box display={'flex'} justifyContent={'center'} mt={1}>
                    <SliderDots currentDot={currentSlide} totalDots={config.nbFiles}/>
                </Box>
            )}

            {showViewButton && (
                <ShadowButtonIcon
                    className={cx(classes.viewButton, ' absoluteCenter')}
                    label={t('VIEW_IMAGES')}
                    icon={<PhotoSizeSelectActualIcon/>}
                    onClick={() => setImageDialogOpen(0)}
                    aria-label={'view images'}
                />
            )}
        </>
    );
};

export default NewMediaSlider;