import React, {useMemo} from 'react';
import LockOpenIcon from '@icons/subscribe/lockOpen.svg';
import LockClosedIcon from '@icons/subscribe/lockClosed.svg';
import SVGImage from '@components/Utils/SVGImage';
import {makeStyles} from '@theme/makeStyles';
import {ButtonProps, IconButton, Typography} from '@mui/material';
import {MediaSliderFile} from '@components/NewMediaSlider/utils';
import {secondsToDuration} from '../../../Utils/stringUtils';
import {useTranslation} from 'react-i18next';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import {useAppContext} from '@context/AppContext';

const useStyles = makeStyles()(() => ({
    lockIcon: {
        borderRadius: '100%',
        // eslint-disable-next-line custom-rules/no-hardcoded-color-use-styles
        backgroundColor: 'white',
        padding: 6,
        position: 'absolute',
        top: 0,
        left: 0,
        width: 36,
        height: 36,
        // eslint-disable-next-line custom-rules/no-hardcoded-color-use-styles
        border: '1px solid #CCCCCC',
        zIndex: 1,

        '& > span': {
            position: 'absolute',
            top: 3,
            left: 3
        }
    },
    bannerWrapper: {paddingTop: 3},
    banner: {
        backgroundColor: 'rgba(0, 0, 0, .5)',
        top: 6,
        left: 18,
        borderRadius: 5,
        padding: '5px 10px',
        userSelect: 'none'
    },
    bannerSmall: {
        padding: '2px 4px',
        marginTop: 3
    },
    bannerInfo: {
        paddingLeft: '25px !important',
        marginLeft: 16
    },
    soundIcon: {
        // eslint-disable-next-line custom-rules/no-hardcoded-color-use-styles
        color: 'white',
        backgroundColor: 'rgba(0, 0, 0, .5)',
        borderRadius: '100%'
    }
}));

interface WithSize {
    size?: 'normal'|'small';
}

interface LockBadgeProps extends WithSize {
    locked: boolean;
    files: MediaSliderFile[];
}

export const LockBadge = (props: LockBadgeProps): JSX.Element => {
    const {locked, files, size = 'normal'} = props;
    const {classes, cx} = useStyles();
    const {t} = useTranslation('post');

    const video = files.find(f => f.type === 'video');
    const nbImages = files.length - (video ? 1 : 0);
    const {language} = useAppContext();

    const textToShow = useMemo(() => {
        if (video) {
            let duration = null;

            if (video.file && video.file.duration) {
                duration = secondsToDuration(video.file.duration);
            }

            if (nbImages === 0) {
                return t('VIDEO') + (duration ? `: ${duration}` : '');
            }

            return t('NB_PHOTOS', {count: nbImages}) + ` & ${t('VIDEO')}` + (duration ? ` (${duration})` : '');
        }

        return t('NB_PHOTOS', {count: nbImages});
    }, [video, nbImages, language]);

    return (
        <div>
            <div className={classes.lockIcon}>
                <SVGImage svg={locked ? LockClosedIcon : LockOpenIcon} removeStroke/>
            </div>
            <div className={classes.bannerWrapper}>
                <Typography className={cx(classes.banner, classes.bannerInfo, size === 'small' ? classes.bannerSmall : undefined)} color={'white'} fontWeight={700} fontSize={15}>
                    {textToShow}
                </Typography>
            </div>
        </div>
    );
};

export const SpoilerBadge = (props: WithSize): JSX.Element => {
    const {size = 'normal'} = props;
    const {classes, cx} = useStyles();
    const {t} = useTranslation('post');

    return (
        <div className={classes.bannerWrapper}>
            <Typography className={cx(classes.banner, size === 'small' ? classes.bannerSmall : undefined)} pl={'25px'} color={'white'} fontWeight={700} fontSize={15}>
                {t('SPOILER')}
            </Typography>
        </div>
    );
};

interface SoundBadgeProps extends ButtonProps {
    muted?: boolean;
}

export const SoundBadge = (props: SoundBadgeProps): JSX.Element => {
    const {muted, className, ...rest} = props;
    const {classes, cx} = useStyles();

    return (
        <IconButton disableRipple className={cx(classes.soundIcon, className) + ' swiper-no-swiping'} {...rest}>
            {muted ? <VolumeOffIcon/> : <VolumeUpIcon/>}
        </IconButton>
    );
};
