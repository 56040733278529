import React from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Grid, IconButton, IconButtonProps, Theme } from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import useSwiperClick from '../../Hooks/Binders/useSwiperClick';

const useStyles = makeStyles()((theme: Theme) => ({
    bigPlayBtn: {
        background: theme.palette.primary.main,
        borderRadius: '100%',
        padding: theme.spacing(2),
        width: 48,
        height: 48,

        '&:hover': {
            background: theme.palette.primary.main
        },

        // For safari 12.1 or higher purpose
        '& > span': {
            height: 15
        }
    },

    bigPlayIcon: {
        width: 36,
        height: 36,
        // eslint-disable-next-line custom-rules/no-hardcoded-color-use-styles
        color: '#fff',
        zIndex: 3
    },

    backDrop: {
        position: 'absolute',
        top: 0, left: 0, right: 0, bottom: 0,
        zIndex: 1,
        cursor: 'pointer'
    }
}));

const BigPlayButton = (props: IconButtonProps & {clickPreview: () => void}): JSX.Element => {
    const {clickPreview, ...rest} = props;
    const { classes } = useStyles();

    const swipable = useSwiperClick(clickPreview, 'initialize video');

    return (
        <Grid aria-label='literally irrelevant' container alignItems='center' justifyContent='center' className={classes.backDrop}
            onClick={e => {e.preventDefault(); e.stopPropagation();}} {...swipable} tabIndex={0}
        >
            <IconButton className={classes.bigPlayBtn} {...rest} size="large">
                <PlayArrowIcon className={classes.bigPlayIcon} />
            </IconButton>
        </Grid>
    );
};

export default BigPlayButton;
