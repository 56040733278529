import {useDialog} from '@context/ModalContext';
import {SubscriptionBundlesModalData} from '@components/Profile/Actions/Subscribe/SubscriptionBundlesModal';
import {AuthenticatedModalData} from '@components/Profile/Actions/AuthenticateModal';
import {HandlePay, MediaPaymentModalData} from '@components/Payment/MediaPaymentModal';
import {useCallback} from 'react';
import {MediaSliderConfiguration} from '@components/NewMediaSlider/utils';
import {useAppContext} from '@context/AppContext';

const useFilePaymentModal = (config: MediaSliderConfiguration, payHandler: HandlePay, price?: number): (e?: React.MouseEvent | React.TouchEvent) => void => {
    const [openSubscriptionBundles] = useDialog<SubscriptionBundlesModalData>('SubscriptionBundleModal');
    const [openAuthenticated] = useDialog<AuthenticatedModalData>('AuthenticatedModal');
    const [openMediaPaymentModal] = useDialog<MediaPaymentModalData>('MediaPaymentModal');

    const {authUser} = useAppContext();

    return useCallback((e: React.MouseEvent | React.TouchEvent) => {
        const isMyContent = authUser && authUser.data.id === config.user.id;

        // Anything that doesn't swipe should also not click
        if (e && (e.target as HTMLElement).classList.contains('swiper-no-swiping')) {
            return;
        }

        if (!authUser && !config.isVisible) {
            openAuthenticated({
                type: price && price > 0 ? 'buy_post' : 'subscribe',
                user: config.user.username
            });
        }
        else if (!config.isVisible && !isMyContent) {
            if (price && price > 0) { // PPV
                openMediaPaymentModal({
                    handlePay: payHandler,
                    price: price,
                    user: config.user.id,
                    contentType: 'post',
                    authenticateModalType: 'buy_post'
                });
            } else { // subscribe to unlock
                openSubscriptionBundles({user: config.user.id});
            }
        }
    }, [authUser, config]);
};

export default useFilePaymentModal;