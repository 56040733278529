import React from 'react';
import {MediaSliderConfiguration, MediaSliderFile} from '@components/NewMediaSlider/utils';
import {IMAGE_LOADER, POST_BORDER_RADIUS} from '../../Constants';
import Image from 'next/image';
import EyePurchaser from '@components/NewMediaSlider/EyePurchaser';
import useFilePaymentModal from '@hooks/Utils/useFilePaymentModal';
import {HandlePay} from '@components/Payment/MediaPaymentModal';
import {makeStyles} from '@theme/makeStyles';
import {Theme} from '@mui/material';
import BigPlayButton from '@components/VideoPlayer/BigPlayButton';
import SpoilerPreview from '@components/NewMediaSlider/SpoilerPreview';
import PostUnlockButton from '@components/NewMediaSlider/PostUnlockButton';
import {useNewMediaSliderContext} from '@components/NewMediaSlider/Context';
import {getDrmTokenFromUrl} from '@components/VideoPlayer/DRM/drmUtils';
import dynamic from 'next/dynamic';
import dynamicGuard from '@components/Utils/dynamicGuard';

const DrmVideoPlayer = dynamic(() => import('@components/VideoPlayer/DRM/DrmVideoPlayer2').catch(dynamicGuard), {ssr: false});

const useStyles = makeStyles<[number, number]>()((theme: Theme, [containerWidth, containerHeight]) => ({
    containerDimensions: {
        width: containerWidth,
        height: containerHeight
    },
    withBackground: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    rounded: {
        borderRadius: POST_BORDER_RADIUS
    },
    video: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }
}));

interface RenderFileOpts {
    disableRoundedBorders?: boolean;
    fillImage?: boolean;
}

export const useFileRenderer = (config: MediaSliderConfiguration, file: MediaSliderFile, payHandler: HandlePay, price?: number, opts?: RenderFileOpts): JSX.Element => {
    const handlePay = useFilePaymentModal(config, payHandler, price);
    const {classes, cx} = useStyles([config.width, config.height]);
    const {isMediaPlayable} = useNewMediaSliderContext();

    const roundedClass = opts && opts.disableRoundedBorders ? undefined : classes.rounded;

    const showSpoilerPreview = config.preview && (
        config.type === 'paid'
        || (config.type === 'subscribers' && config.isVisible)
        || (config.type === 'free')
    );
    let imageToUse = showSpoilerPreview ? config.preview.urls.nonBlur : (config.isVisible ? file.urls.nonBlur : file.urls.blur);

    // Hidden media
    if (!showSpoilerPreview && !config.isVisible && config.isHidden) {
        return <EyePurchaser config={config} files={config.files} price={price} paymentCallback={handlePay} className={classes.containerDimensions} hidePaymentBox={config.type !== 'subscribers'}/>;
    }

    if (!imageToUse) {
        return <div/>;
    }

    if (imageToUse.isDrm) {
        const VideoComponent = <DrmVideoPlayer
            token={getDrmTokenFromUrl(imageToUse.url)}
            isImage={imageToUse.type !== 'video'}
            poster={file.urls.blur && file.urls.blur.url}
            fileId={file && file.id}
        />;

        if (file.type === 'video') {
            return (
                <div>
                    {config.isVisible && <BigPlayButton clickPreview={() => {/* no-op */}} />}
                    {VideoComponent}
                </div>
            );
        }

        return VideoComponent;
    }

    // Subscribers post + not visible + has preview -> use blur from preview
    if (config.type === 'subscribers' && !config.isVisible && config.preview && config.preview.urls.blur) {
        imageToUse = config.preview.urls.blur;
    }

    let ImageComponent = (
        <Image
            src={imageToUse.url}
            blurDataURL={file.urls.blur && file.urls.blur.url}
            placeholder={file.urls.blur && 'blur' || undefined}
            layout='fixed'
            width={config.width}
            height={config.height}
            loader={IMAGE_LOADER}
            unoptimized
            onDragStart={(e) => e.preventDefault()}
            onContextMenu={(e) => e.preventDefault()}
            className={roundedClass}
        />
    );

    if (opts && opts.fillImage) {
        ImageComponent = (
            <div
                className={roundedClass}
                style={{
                    backgroundImage: `url(${imageToUse.url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: config.width,
                    height: config.height
                }}
            />
        );
    }

    // Show poster with videos -> do not append player in post
    if (file.type === 'video') {
        // Wait for container width to be available
        if (config.width === 0 || config.height === 0) {
            return <div/>;
        }

        const isHorizontal = imageToUse.width > imageToUse.height;
        let width, height;

        if (isHorizontal) {
            width = config.width;
            height = (imageToUse.height/imageToUse.width)*config.width;
        }
        else {
            width = (imageToUse.width/imageToUse.height)*config.height;
            height = config.height;
        }

        ImageComponent = (
            <div
                className={cx(classes.video, roundedClass)}
                style={
                    {
                        width: config.width,
                        height: config.height,
                        backgroundImage: file.urls.blur && file.urls.blur.url ? `url(${file.urls.blur.url})` : 'none'
                    }
                }>
                {config.isVisible && (config.type === 'free' || isMediaPlayable) && <BigPlayButton clickPreview={() => {/* no-op */}} />}
                <Image
                    src={imageToUse.url}
                    blurDataURL={file.urls.blur && file.urls.blur.url}
                    placeholder={file.urls.blur && 'blur' || undefined}
                    layout='fixed'
                    width={width}
                    height={height}
                    loader={IMAGE_LOADER}
                    unoptimized
                    onDragStart={(e) => e.preventDefault()}
                    onContextMenu={(e) => e.preventDefault()}
                    className={roundedClass}
                />
            </div>
        );
    }

    if (showSpoilerPreview) {
        return <SpoilerPreview config={config} payHandler={handlePay} staticVideo={isMediaPlayable && config.isVisible} />;
    }

    if (config.isVisible && config.type === 'subscribers') {
        return <div style={{width: '100%', position: 'relative'}}>
            {ImageComponent}
            <div style={{position: 'absolute', bottom: 0, left: 0, padding: 10, width: '100%'}}>
                <PostUnlockButton config={config} payHandler={handlePay} price={price} />
            </div>
        </div>;
    }

    if (config.isVisible) {
        return ImageComponent;
    }

    return (
        <div>
            <EyePurchaser config={config} files={config.files} price={price} blurred paymentCallback={handlePay} hidePaymentBox={!!price} />
            {ImageComponent}
        </div>
    );
};