import useCurrency from '@hooks/Utils/useCurrency';
import {useTranslation} from 'react-i18next';
import Tooltip from '@components/Utils/Tooltip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SVGImage from '@components/Utils/SVGImage';
import LockIconOpen from '@icons/post/icon-lock-open.svg';
import LockIcon from '@icons/post/icon-lock.svg';
import {Grid, Theme, Typography} from '@mui/material';
import React from 'react';
import {makeStyles} from '@theme/makeStyles';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        borderRadius: 6,
        border: `2px solid ${theme.palette.primary.main}`,
        flexWrap: 'nowrap'
    },
    gridLock: {
        backgroundColor: theme.palette.primary.main,
        minWidth: 33,
        padding: 5
    },
    gridText: {
        backgroundColor: 'rgba(0, 0, 0, 0.40);',
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        padding: 5
    },
    darkerGridText: {
        backgroundColor: 'rgba(0, 0, 0, 0.60);',
    },
    // If we use Grid props with will make a gap and there will not work as we want both backgrounds, better use alignSelf on each item
    alignmentLockText: {
        alignSelf: 'center',
        userSelect: 'none',
        // eslint-disable-next-line custom-rules/no-hardcoded-color-use-styles
        fill: 'white'
    },
    pendingApproval: {
        width: 20,
        height: 20,
        marginTop: 4,
        cursor: 'pointer'
    }
}));

interface DisplayInfoProps {
    price: number,
    private: boolean,
    isMyContent: boolean,
    status: string;
    openLock?: boolean;
}

const DisplayInfo = (props: DisplayInfoProps): JSX.Element => {
    const { price, private: isPrivate, status, isMyContent, openLock } = props;
    const {classes, cx} = useStyles();
    const currency = useCurrency();
    const { t } = useTranslation(['profile', 'account']);
    const isFree = !price && !isPrivate;

    const getText = (): string|null => {
        if (price) {
            return t('PAY_CONTENT', {price: currency(price / 100)});
        } else if (!price && isPrivate) {
            return t('SUBSCRIBERS_CONTENT');
        } else if (isFree) {
            return t('FREE_CONTENT');
        }

        return null;
    };

    if (!getText()) {
        return <></>;
    }

    const showPending = isMyContent && (status === 'pending_approval' || status === 'rejected');

    const Icon = showPending ? (
        <Tooltip title={t('account:PENDING_APPROVAL')}>
            <AccessTimeIcon className={cx(classes.alignmentLockText, classes.pendingApproval)} />
        </Tooltip>
    ) : (
        <SVGImage svg={(openLock || isFree) ? LockIconOpen : LockIcon} width={16} height={20} removeStroke removeMargin className={classes.alignmentLockText}/>
    );

    return (
        <Grid container className={classes.container}>
            <Grid item xs={3} container justifyContent={'center'} className={classes.gridLock}>
                {Icon}
            </Grid>
            <Grid item xs container justifyContent={'center'} className={cx(classes.gridText, isMyContent && classes.darkerGridText)}>
                <Typography variant={'h2'} className={classes.alignmentLockText} style={{color: '#fff'}}>
                    {getText()}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default DisplayInfo;
